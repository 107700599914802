import React from 'react';
import './App.css';
import Button from '@material-ui/core/Button';
import {BarChart} from "@material-ui/icons";
import {Container, createMuiTheme, ThemeProvider, Typography} from "@material-ui/core";
import { lightBlue } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue[200]
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Typography variant="h1" component="h2" gutterBottom style={{marginBottom: 0}}>
            Budúca vláda.sk
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            projekt, ktorého cieľom je dlhodobo prispieť k lepšiemu personálnemu obsadeniu ministerských postov
            vo Vláde SR
          </Typography>
          <Typography variant="body1" gutterBottom style={{margin: "1.5rem 0", textAlign: "justify"}}>
            Chceme vytvoriť webový portál, ktorý bude mať dve úlohy. Predstaviť odborné predpoklady
            kandidátov jednotlivých politických strán na ministrov a ministerky vo vláde Slovenskej republiky.
            A dať verejnosti možnosť poslať politikom prostredníctvom online hlasovania odkaz, že voličom tejto
            krajiny záleží na odbornosti členov Vlády. Tak budú politické strany motivované k hľadaniu odborných
            osobností na ministerské posty.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<BarChart />}
          >
            Preferencie
          </Button>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;
